export const Colors = {
  primary: {
    main: "#7A65FF",
    light: "#E1DCFC",
    dark: "#5546B2",
  },
  secondary: {
    main: "#C965FF",
  },
  gray: {
    100: "#F2F2F2",
    200: "#E0E0E0",
    300: "#828282",
    500: "#333333",
    900: "#1F1F1F",
    1000: "#101010",
  },
};
